import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const HomebuyerEducationIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={PrimaryStrokeColor}
      d="M94.743 40.67 50.875.995a3.802 3.802 0 0 0-5.126 0L1.257 41.284a3.768 3.768 0 0 0-.994 4.182 3.789 3.789 0 0 0 3.558 2.437H5.3c.761 0 1.385.614 1.385 1.364v34.492c0 2.095 1.715 3.8 3.82 3.8h7.485v-2.436h-7.485c-.76 0-1.384-.614-1.384-1.364V49.267c0-2.095-1.715-3.801-3.82-3.801H3.82c-.878 0-1.209-.672-1.287-.877-.078-.205-.283-.916.36-1.491l44.482-40.3a1.389 1.389 0 0 1 1.862 0l43.867 39.676c.644.585.439 1.296.361 1.491-.078.205-.41.877-1.286.877h-1.472c-2.105 0-3.82 1.706-3.82 3.801V83.75c0 .75-.615 1.365-1.385 1.365H77.98v2.436h7.524c2.106 0 3.82-1.706 3.82-3.801V48.643c0-.75.615-1.364 1.385-1.364h1.472a3.802 3.802 0 0 0 3.567-2.437 3.788 3.788 0 0 0-.995-4.18l-.01.009Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="m77.034 51.382-2.115-.487-.497-.079c-.975-.155-1.784-.292-2.846-.321v-2.846c0-.643-.507-1.18-1.15-1.218-7.397-.42-14.863 2.368-22.202 8.294-7.339-5.926-14.804-8.713-22.202-8.294a1.226 1.226 0 0 0-1.15 1.218v2.817c-1.189.02-2.27.185-3.752.438l-2.183.468a1.21 1.21 0 0 0-.965 1.19v38.487c0 .39.185.76.507.984a1.18 1.18 0 0 0 1.091.166c10-3.294 20.818.175 28.128 3.664.02 0 .039.01.068.02.02 0 .049.02.068.03.03 0 .049.019.078.019.02 0 .03.01.04.01.067.019.145.019.214.019h.098c.067 0 .145 0 .213-.02.02 0 .03-.01.04-.01.029 0 .048-.019.077-.019.02 0 .05-.02.069-.02.02 0 .039-.01.068-.019 10.497-5.02 19.794-6.257 27.63-3.664.37.117.78.058 1.092-.166.312-.234.507-.595.507-.984V52.57a1.22 1.22 0 0 0-.946-1.19h.02Zm-7.895-2.554v33.313c-7.095-.283-13.508 4.756-19.697 10V56.868c6.57-5.39 13.167-8.119 19.697-8.04Zm-41.83 0h.195c6.442 0 12.991 2.7 19.502 8.04V92.14c-6.033-5.107-12.27-10.009-19.142-10.009-.185 0-.37 0-.555.01V48.828Zm-6.9 40.593V53.555l1.169-.253c1.296-.224 2.251-.37 3.285-.39v30.554a1.229 1.229 0 0 0 1.383 1.209c5.195-.712 10.253 2.232 15.185 6.062-6.335-2.076-13.79-3.226-21.032-1.316h.01Zm55.134 0c-6.14-1.618-13.002-1.189-20.487 1.277 4.922-3.82 9.96-6.735 15.136-6.023a1.23 1.23 0 0 0 1.384-1.209V52.931c.858.03 1.54.137 2.446.293l1.52.322V89.43v-.01Z"
    />
  </svg>
)

export const HomebuyerEducationIcon = memo(HomebuyerEducationIconComponent)
HomebuyerEducationIcon.displayName = "HomebuyerEducationIcon"

export default HomebuyerEducationIcon
