import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const ExploreOptionsIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <g strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2.5}>
      <path
        stroke={PrimaryStrokeColor}
        d="m24.168 2.184 22.171 29.04H35.798v12.71C19.976 55.348 13.57 74.678 13.57 90.603l-.095-59.378H2.943l21.225-29.04Z"
      />
      <path
        stroke={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
        d="M13.57 94.595v-3.993c0-29.223 19.824-56.472 53.54-56.588V21.996l26.666 21.582L67.11 65.161v-11.44C52.064 53.21 33.3 68.277 32.95 90.6v3.994"
      />
    </g>
  </svg>
)

export const ExploreOptionsIcon = memo(ExploreOptionsIconComponent)
ExploreOptionsIcon.displayName = "ExploreOptionsIcon"

export default ExploreOptionsIcon
