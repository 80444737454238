import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const WhrcAltIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    {" "}
    <path
      fill={PrimaryStrokeColor}
      stroke={PrimaryStrokeColor}
      d="m19.438 52.596 1.266 15.06a328.5 328.5 0 0 0-4.83.38l3.563-15.44Zm54.264 14.96c1.623.11 3.217.22 4.79.35l-3.533-15.31-1.257 14.96Zm-26.504-55.55c-4.265 4.74-15.874 17.096-22.149 23.035l.465 32.306c7.146-.41 14.687-.629 22.486-.629 7.215 0 14.212.19 20.882.539l.475-32.536c-6.274-5.868-17.893-17.984-22.159-22.725v.01Zm48.307.48v57.276a262.627 262.627 0 0 0-12.183-1.437l-3.345-26.588-.02-.25c3.504 2.117 7.344 4.462 10.877 6.318v-3.403L47.198 8.054 3.553 44.402v3.403c3.523-1.856 7.363-4.111 10.867-6.138v.06l-3.375 26.748c-3.682.369-7.215.798-10.55 1.277V12.485C.495 5.868 5.819.5 12.38.5h71.238c6.562 0 11.886 5.37 11.886 11.986Zm-75.157 6.447c-2.227-.52-4.612 1.317-5.324 4.101-.723 2.785.494 5.45 2.721 5.969 2.227.519 4.612-1.318 5.325-4.102.722-2.785-.495-5.45-2.722-5.968Zm59.025 4.101c-.722-2.784-3.107-4.62-5.324-4.101-2.227.518-3.435 3.183-2.722 5.968.722 2.784 3.108 4.62 5.325 4.102 2.226-.52 3.434-3.184 2.721-5.969ZM13.39 79.284h2.207l3.464 13.642 3.82-13.643h2.317l3.642 13.593 3.483-13.593h2.227l-4.592 16.088h-2.326l-3.691-12.944-3.82 12.944-2.297.02-4.434-16.108Zm34.165 8.792h-8.076v7.295H37.41V79.283h2.069v7.066h8.076v-7.066h2.068v16.088h-2.068v-7.295Zm11.272-8.793c3.731 0 5.503 2.096 5.503 4.73 0 2.057-1.118 4.013-3.78 4.612l3.978 6.736h-2.484l-3.78-6.577h-2.505v6.577h-2.068V79.273h5.116l.02.01Zm0 1.727H55.78v6.088h3.048c2.346 0 3.365-1.288 3.365-3.094 0-1.807-1-3.004-3.365-3.004v.01Zm16.43-1.937c3.433 0 6.165 1.757 7.352 4.87h-2.484c-.86-1.935-2.553-3.023-4.869-3.023-3.325 0-5.8 2.445-5.8 6.397 0 3.952 2.485 6.368 5.8 6.368 2.326 0 4.008-1.088 4.87-3.005h2.483c-1.187 3.094-3.919 4.821-7.353 4.821-4.434 0-7.917-3.373-7.917-8.194 0-4.82 3.483-8.243 7.917-8.243v.01Z"
    />
  </svg>
)

export const WhrcAltIcon = memo(WhrcAltIconComponent)
WhrcAltIcon.displayName = "WhrcAltIcon"

export default WhrcAltIcon
