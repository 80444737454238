import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const WhrcIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={PrimaryStrokeColor}
      stroke={PrimaryStrokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.43}
      d="M77.95 22.11c2.25-.52 3.47-3.21 2.75-6.01-.73-2.8-3.14-4.65-5.38-4.13-2.25.52-3.47 3.21-2.75 6.01.73 2.8 3.14 4.65 5.38 4.13ZM18.41 22.11c2.25.52 4.66-1.33 5.38-4.13.73-2.8-.5-5.49-2.75-6.01-2.25-.52-4.66 1.33-5.38 4.13-.73 2.8.5 5.49 2.75 6.01ZM11.66 61.9c-3.72.38-7.29.81-10.66 1.28v2.64c15.55-1.33 31.97-2.05 48.97-2.05s32.06.67 47.03 1.89v-2.48c-3.87-.55-7.99-1.03-12.31-1.45l-3.38-26.79-.02-.25c3.54 2.13 7.42 4.49 10.99 6.37v-3.43L48.18 1 4.09 37.64v3.43c3.56-1.87 7.44-4.14 10.98-6.18v.06L11.66 61.9Zm64.57-16.01 3.57 15.42c-1.59-.13-3.2-.24-4.84-.35l1.27-15.07ZM48.18 4.99c4.31 4.78 16.05 16.98 22.39 22.9l-.48 32.78c-6.74-.35-13.8-.54-21.09-.54-7.88 0-15.5.22-22.72.63L25.8 28.2c6.34-5.98 18.08-18.44 22.38-23.21Zm-28.05 40.9 1.28 15.18c-1.65.12-3.28.24-4.88.38l3.6-15.56Z"
    />
    <path
      fill={PrimaryStrokeColor}
      stroke={PrimaryStrokeColor}
      d="M13.62 80.75h2.23l3.5 13.67 3.86-13.67h2.34l3.68 13.62 3.52-13.62H35l-4.64 16.12h-2.35L24.28 83.9l-3.86 12.97-2.32.02-4.48-16.14ZM48.14 89.56h-8.16v7.31h-2.09V80.75h2.09v7.08h8.16v-7.08h2.09v16.12h-2.09v-7.31ZM59.53 80.75c3.77 0 5.56 2.1 5.56 4.74 0 2.06-1.13 4.02-3.82 4.62l4.02 6.75h-2.51l-3.82-6.59h-2.53v6.59h-2.09V80.74h5.17l.02.01Zm0 1.73h-3.08v6.1h3.08c2.37 0 3.4-1.29 3.4-3.1s-1.01-3.01-3.4-3.01v.01ZM76.13 80.54c3.47 0 6.23 1.76 7.43 4.88h-2.51c-.87-1.94-2.58-3.03-4.92-3.03-3.36 0-5.86 2.45-5.86 6.41s2.51 6.38 5.86 6.38c2.35 0 4.05-1.09 4.92-3.01h2.51c-1.2 3.1-3.96 4.83-7.43 4.83-4.48 0-8-3.38-8-8.21 0-4.83 3.52-8.26 8-8.26v.01Z"
    />
  </svg>
)

export const WhrcIcon = memo(WhrcIconComponent)
WhrcIcon.displayName = "WhrcIcon"
export default WhrcIcon
