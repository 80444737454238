import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const SelfHelpHousingIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={PrimaryStrokeColor}
      d="M94.743 40.876 50.875.994a3.804 3.804 0 0 0-5.136 0L1.257 41.49a3.786 3.786 0 0 0-.994 4.201 3.789 3.789 0 0 0 3.557 2.437h7.427c.76 0 1.384.614 1.384 1.384v34.667a3.827 3.827 0 0 0 3.82 3.82h26.452v-2.436H16.452c-.76 0-1.385-.614-1.385-1.384V49.51a3.827 3.827 0 0 0-3.82-3.82H3.82c-.877 0-1.208-.673-1.286-.878-.078-.205-.292-.926.36-1.52L47.387 2.797c.263-.244.594-.36.926-.36.331 0 .663.117.926.36L93.105 42.68c.653.594.439 1.306.36 1.52-.077.205-.408.877-1.286.877h-7.416a3.827 3.827 0 0 0-3.82 3.82v35.282c0 .76-.615 1.384-1.385 1.384H53.926v2.436h25.632a3.827 3.827 0 0 0 3.82-3.82V48.897c0-.76.615-1.384 1.385-1.384h7.416a3.789 3.789 0 0 0 3.558-2.437 3.786 3.786 0 0 0-.994-4.2Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M57.044 55.017a3.082 3.082 0 0 1 3.08 3.09v1.218h6.745l.02-19.658h-6.745v1.208a3.076 3.076 0 0 1-3.09 3.08 3.088 3.088 0 0 1-3.08-3.09v-1.218l-14.131-.02c-5.42 0-9.835 4.396-9.835 9.815v1.219l9.825.01a3.082 3.082 0 0 1 3.08 3.089v4.308h2.426v1.218h6.17v-1.218h2.436c0-1.647 1.443-3.09 3.09-3.08l.01.03Zm-5.39 1.862H45.35v-3.09c0-3.04-2.475-5.526-5.516-5.526h-7.29c.584-3.509 3.635-6.179 7.3-6.17h11.831a5.543 5.543 0 0 0 5.38 4.328 5.53 5.53 0 0 0 5.39-4.298h2.008l-.02 14.785h-2.008c-.555-2.466-2.758-4.308-5.38-4.308a5.538 5.538 0 0 0-5.39 4.298v-.02Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M53.955 58.107h-2.437v1.218L51.5 85.562V89.43a3.082 3.082 0 0 1-3.09 3.08 3.082 3.082 0 0 1-3.08-3.09v-3.859l.02-26.237v-1.218h-2.436v1.218l-.02 26.246v3.86c0 3.04 2.476 5.526 5.516 5.526a5.51 5.51 0 0 0 3.909-1.618 5.537 5.537 0 0 0 1.627-3.898V85.572l.02-27.446-.01-.02Z"
    />
  </svg>
)

export const SelfHelpHousingIcon = memo(SelfHelpHousingIconComponent)
SelfHelpHousingIcon.displayName = "SelfHelpHousingIcon"

export default SelfHelpHousingIcon
