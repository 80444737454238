import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const HomeownerIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M70.515 46.846c-2.802 0-5.538-.557-8.122-1.65-.608-.253-.912-.992-.665-1.64.237-.647.931-.96 1.539-.708a18.52 18.52 0 0 0 7.239 1.468c10.81 0 19.616-9.372 19.616-20.898 0-11.527-8.796-20.888-19.607-20.888-10.81 0-19.617 9.37-19.617 20.898 0 1.508.152 3.015.447 4.473.142.678-.266 1.356-.912 1.508-.636.152-1.273-.284-1.415-.972a25.325 25.325 0 0 1-.504-5.02c0-12.913 9.86-23.427 21.992-23.427 12.13 0 21.991 10.504 21.991 23.428 0 12.923-9.86 23.428-21.991 23.428h.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M13.337 78.815H2.29C1.026 78.815 0 77.712 0 76.365v-11.85c0-.638.237-1.265.665-1.73l19.16-20.565-.598-.637a2.554 2.554 0 0 1-.665-1.791c.02-.668.285-1.306.75-1.751l1.881-1.832a26.141 26.141 0 0 1-.427-4.655c0-13.774 10.44-24.977 23.283-24.977h.276c1.871 0 3.733.243 5.529.719.636.172 1.026.86.864 1.538-.161.678-.807 1.093-1.444.921a19.464 19.464 0 0 0-4.959-.648h-.275c-11.523 0-20.9 10.07-20.909 22.447 0 1.579.171 3.198.513 4.837l.152.719-2.84 2.752 2.204 2.369L2.356 64.566l.019 11.719h10.962l2.176-2.368h4.37v-5.88l6.678-5.971h4.37v-5.88L33 53.97l3.306 2.368 2.575-3.056.674.162c6.184 1.477 12.549-.071 17.441-4.25 5.054-4.312 7.96-10.748 7.951-17.65v-.294c0-7.114-3.23-13.844-8.635-18.014-.532-.404-.646-1.204-.266-1.77a1.147 1.147 0 0 1 1.663-.284c6.023 4.635 9.613 12.134 9.613 20.058v.294c0 7.68-3.22 14.826-8.844 19.623-5.282 4.503-12.083 6.274-18.761 4.898l-1.7 2.014c-.77.91-2.015 1.072-2.974.404l-1.748-1.255v4.909c0 1.346-1.026 2.449-2.29 2.449h-3.58L22.256 69.2v4.776c0 1.356-1.026 2.45-2.289 2.45h-3.467l-1.54 1.649a2.202 2.202 0 0 1-1.624.719v.02Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M7.562 72.126c-.314 0-.618-.132-.855-.385a1.323 1.323 0 0 1 .028-1.791L27.36 48.617a1.136 1.136 0 0 1 1.681.03 1.324 1.324 0 0 1-.028 1.791L8.388 71.772a1.126 1.126 0 0 1-.826.354Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M85.22 93.57H55.515c-1.33 0-2.413-1.154-2.413-2.57V73.622l-3.524-.04c-1.007 0-1.88-.648-2.251-1.64-.361-1.002-.114-2.105.627-2.823L68.89 48.819a2.298 2.298 0 0 1 3.239 0l20.643 19.987c.74.719.987 1.832.627 2.823-.362.992-1.245 1.64-2.252 1.64h-3.486l-.038 17.72c0 1.417-1.083 2.57-2.413 2.57l.01.01ZM70.534 50.69 49.55 70.992l3.514.06c1.33 0 2.413 1.154 2.413 2.571V91l4.18.04H85.22l.038-17.72c0-1.416 1.083-2.57 2.413-2.57h3.487L70.534 50.691Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M70.515 50.803c-.655 0-1.187-.567-1.187-1.265v-6.376c0-.698.532-1.265 1.187-1.265.656 0 1.188.567 1.188 1.265v6.376c0 .698-.532 1.265-1.188 1.265ZM50.212 30.94c2.864 0 5.185-2.472 5.185-5.523 0-3.05-2.321-5.523-5.185-5.523-2.863 0-5.184 2.473-5.184 5.523s2.32 5.524 5.184 5.524Z"
    />
  </svg>
)

export const HomeownerIcon = memo(HomeownerIconComponent)
HomeownerIcon.displayName = "HomeownerIcon"

export default HomeownerIcon
