import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const DownPaymentAssistanceIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={PrimaryStrokeColor}
      d="M18.118 66.496a1.21 1.21 0 0 1-.955-1.966l9.074-11.314c1.696-1.908 5.292-5.037 10.72-5.037 3.753 0 7.222 1.308 9.513 3.574.526.523 1.189.804 1.89.804h11.394c3.713 0 6.735 3.003 6.735 6.694a6.633 6.633 0 0 1-1.97 4.726 6.692 6.692 0 0 1-4.755 1.957h-16.53a1.215 1.215 0 0 1-1.218-1.21c0-.67.546-1.212 1.218-1.212h16.53c1.15 0 2.222-.445 3.04-1.25a4.236 4.236 0 0 0 1.258-3.021c0-2.354-1.93-4.272-4.298-4.272H48.37c-1.345 0-2.621-.532-3.606-1.51-1.813-1.803-4.727-2.868-7.797-2.868-4.473 0-7.465 2.615-8.859 4.175L19.083 66.03c-.244.3-.594.455-.955.455l-.01.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M51.255 80.221h-.331l-20.107-1.036a1.207 1.207 0 0 1-1.15-1.27 1.215 1.215 0 0 1 1.277-1.142l20.097 1.036c.487.02.965-.038 1.423-.174l24.053-7.158a2.73 2.73 0 0 0 1.19-.736L93.486 52.79a.339.339 0 0 0 0-.455l-1.18-1.172a4.178 4.178 0 0 0-2.972-1.22h-.02c-1.13 0-2.183.445-2.972 1.239L75.27 62.34a3.723 3.723 0 0 1-2.622 1.095h-7.992a1.215 1.215 0 0 1-1.218-1.21c0-.67.546-1.212 1.218-1.212h7.992c.332 0 .653-.135.887-.368l11.072-11.158a6.63 6.63 0 0 1 4.697-1.967h.03a6.58 6.58 0 0 1 4.688 1.938L95.2 50.63a2.735 2.735 0 0 1 .078 3.806l-15.78 16.951a5.153 5.153 0 0 1-2.27 1.415l-24.054 7.167a6.655 6.655 0 0 1-1.91.281l-.01-.029Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M29.073 87.282c-.312 0-.624-.116-.858-.358a1.205 1.205 0 0 1 0-1.715l2.759-2.74a1.665 1.665 0 0 0 0-2.374l-14.97-14.878a1.699 1.699 0 0 0-2.389 0l-2.806 2.79a1.223 1.223 0 0 1-1.725 0 1.205 1.205 0 0 1 0-1.715l2.806-2.79a4.154 4.154 0 0 1 5.838 0l14.97 14.879a4.092 4.092 0 0 1 0 5.802l-2.758 2.74a1.224 1.224 0 0 1-.857.36h-.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M24.346 96c-.848 0-1.637-.33-2.232-.92L8.46 81.51a1.205 1.205 0 0 1 0-1.715 1.223 1.223 0 0 1 1.725 0l13.654 13.57c.273.272.75.272 1.014 0l4.035-4.01a.716.716 0 0 0 0-1.017L7.7 67.28a.727.727 0 0 0-1.024 0l-4.035 4.01a.718.718 0 0 0-.214.504c0 .184.078.368.214.504l3.041 3.022c.478.475.478 1.24 0 1.714a1.223 1.223 0 0 1-1.725 0l-3.04-3.022a3.104 3.104 0 0 1-.927-2.218c0-.843.332-1.627.926-2.218l4.035-4.01a3.179 3.179 0 0 1 4.464 0l21.188 21.058a3.131 3.131 0 0 1 0 4.436l-4.035 4.01c-.594.591-1.384.92-2.232.92l.01.01Z"
    />
    <path d="M62.395 44.547c12.041 0 21.802-9.701 21.802-21.668 0-11.967-9.76-21.668-21.802-21.668-12.04 0-21.802 9.7-21.802 21.668 0 11.967 9.761 21.668 21.802 21.668Z" />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M62.395 45.758c-12.69 0-23.02-10.258-23.02-22.88C39.375 10.259 49.705 0 62.395 0s23.02 10.258 23.02 22.879c0 12.621-10.32 22.879-23.02 22.879Zm0-43.336c-11.344 0-20.584 9.172-20.584 20.457 0 11.284 9.23 20.457 20.584 20.457S82.98 34.163 82.98 22.88c0-11.285-9.24-20.457-20.584-20.457Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M68.311 23.799a4.338 4.338 0 0 0-1.394-1.424 8.582 8.582 0 0 0-1.773-.862 30.014 30.014 0 0 0-1.872-.59 12.854 12.854 0 0 1-1.637-.592c-.448-.203-.809-.445-1.082-.736-.234-.242-.341-.562-.341-.968 0-.436.117-.775.35-1.027.264-.281.615-.494 1.063-.63.868-.271 1.9-.28 2.856-.106.331.058.643.145.935.242l.37 2.615h2.34l.253-4.591-.302-.136a11.895 11.895 0 0 0-1.52-.533 15.765 15.765 0 0 0-1.686-.368 9.65 9.65 0 0 0-.692-.087v-3.119h-2.622v3.177a7.287 7.287 0 0 0-1.696.475c-.906.378-1.637.93-2.173 1.647-.546.726-.829 1.656-.829 2.74 0 .853.185 1.58.546 2.17a4.25 4.25 0 0 0 1.394 1.395c.546.34 1.15.62 1.793.833.624.204 1.238.397 1.842.581.585.175 1.14.368 1.628.582.438.193.799.436 1.072.736.234.261.35.61.35 1.056 0 .484-.126.862-.39 1.162-.282.32-.662.571-1.14.736-.886.31-2.027.349-3.157.116a9.98 9.98 0 0 1-1.199-.339l-.37-2.712H56.84l-.088 4.863.312.126a14.516 14.516 0 0 0 4.512 1.007v3.255h2.622v-3.44a8.601 8.601 0 0 0 1.667-.541c.896-.407 1.627-.989 2.163-1.715.546-.746.82-1.676.82-2.76 0-.882-.186-1.628-.547-2.219l.01-.02Z"
    />
  </svg>
)

export const DownPaymentAssistanceIcon = memo(
  DownPaymentAssistanceIconComponent,
)
DownPaymentAssistanceIcon.displayName = "DownPaymentAssistanceIcon"

export default DownPaymentAssistanceIcon
