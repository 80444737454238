import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const BlankIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M76.338 86.099h-2.736a1.226 1.226 0 0 1-1.217-1.234c0-.681.545-1.234 1.217-1.234h2.736c5.658 0 10.265-7.255 10.265-16.18v-40.69c0-.68.545-1.233 1.217-1.233s1.217.552 1.217 1.234v40.69c0 10.276-5.697 18.647-12.699 18.647ZM24.998 86.099h-4.772c-7.001 0-12.698-8.361-12.698-18.647V26.337c0-.681.545-1.234 1.217-1.234s1.217.553 1.217 1.234v41.124c0 8.924 4.607 16.18 10.264 16.18h4.772c.672 0 1.217.553 1.217 1.234 0 .68-.545 1.234-1.217 1.234v-.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="m70.31 73.365-19.223-17.72a2.617 2.617 0 0 0-3.554 0L28.036 73.631a2.665 2.665 0 0 0-.681 2.942 2.629 2.629 0 0 0 2.464 1.708H32.7v15.034c0 1.48 1.188 2.675 2.64 2.675h27.666c1.46 0 2.639-1.204 2.639-2.675v-15.3h2.882c1.1 0 2.065-.672 2.464-1.709a2.669 2.669 0 0 0-.691-2.941h.01Zm-5.025 1.609c-1.46 0-2.639 1.204-2.639 2.675v15.3h-9.134v-11.4h-8.667v11.4H35.71V77.926c0-1.48-1.188-2.675-2.639-2.675h-2.308l18.552-17.117 18.278 16.84h-2.308ZM49.578 50.147a1.226 1.226 0 0 1-1.218-1.234V16.13c0-.681.546-1.234 1.218-1.234.671 0 1.217.553 1.217 1.234v32.783c0 .681-.546 1.234-1.217 1.234ZM87.479 27.857c-4.694 0-8.511-3.87-8.511-8.627 0-4.758 3.817-8.628 8.511-8.628 4.694 0 8.511 3.87 8.511 8.628s-3.817 8.627-8.511 8.627Zm0-14.797c-3.35 0-6.077 2.764-6.077 6.16 0 3.396 2.727 6.16 6.077 6.16s6.077-2.764 6.077-6.16c0-3.396-2.727-6.16-6.077-6.16Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M49.578 17.255c-4.694 0-8.512-3.87-8.512-8.627C41.066 3.87 44.884 0 49.578 0c4.694 0 8.51 3.87 8.51 8.628s-3.816 8.627-8.51 8.627Zm0-14.787c-3.35 0-6.077 2.764-6.077 6.16 0 3.395 2.727 6.16 6.077 6.16s6.076-2.765 6.076-6.16c0-3.396-2.726-6.16-6.076-6.16ZM8.511 27.571C3.817 27.571 0 23.701 0 18.943s3.817-8.627 8.511-8.627c4.694 0 8.512 3.87 8.512 8.627 0 4.758-3.818 8.628-8.512 8.628Zm0-14.788c-3.35 0-6.076 2.765-6.076 6.16 0 3.396 2.726 6.16 6.076 6.16 3.35 0 6.077-2.764 6.077-6.16 0-3.395-2.727-6.16-6.077-6.16Z"
    />{" "}
  </svg>
)

export const BlankIcon = memo(BlankIconComponent)
BlankIcon.displayName = "BlankIcon"

export default BlankIcon
