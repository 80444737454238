import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const CommunityLandTrustIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M79.656 57.235a1.216 1.216 0 0 1-1.219-1.212V16.598c0-.67.546-1.212 1.219-1.212.672 0 1.218.543 1.218 1.212v39.434c0 .67-.546 1.213-1.218 1.213v-.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M18.118 66.451a1.213 1.213 0 0 1-.955-1.97l9.074-11.33c1.696-1.91 5.292-5.044 10.72-5.044 3.753 0 7.222 1.31 9.513 3.58.526.523 1.189.805 1.89.805h11.394c3.713 0 6.735 3.007 6.735 6.703a6.648 6.648 0 0 1-1.97 4.734 6.687 6.687 0 0 1-4.755 1.96h-16.53a1.216 1.216 0 0 1-1.218-1.213c0-.67.546-1.213 1.218-1.213h16.53c1.15 0 2.222-.446 3.04-1.251a4.246 4.246 0 0 0 1.258-3.027c0-2.357-1.93-4.278-4.298-4.278H48.37c-1.345 0-2.621-.533-3.606-1.513-1.813-1.805-4.727-2.872-7.797-2.872-4.473 0-7.465 2.62-8.85 4.181l-9.034 11.282a1.22 1.22 0 0 1-.955.456l-.01.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M51.255 80.197h-.331L30.817 79.16a1.208 1.208 0 1 1 .127-2.415l20.097 1.038c.487.02.965-.04 1.423-.175l24.053-7.169a2.73 2.73 0 0 0 1.19-.737l15.778-16.977a.34.34 0 0 0 0-.456l-1.179-1.173a4.174 4.174 0 0 0-2.972-1.223h-.02a4.16 4.16 0 0 0-2.972 1.242L75.278 62.289a3.68 3.68 0 0 1-2.621 1.097h-7.992a1.216 1.216 0 0 1-1.218-1.213c0-.67.545-1.213 1.218-1.213h7.992c.331 0 .653-.136.887-.368l11.071-11.176a6.625 6.625 0 0 1 4.698-1.969h.03c1.773 0 3.44.689 4.687 1.94l1.18 1.174a2.742 2.742 0 0 1 .078 3.813L79.508 71.35a5.15 5.15 0 0 1-2.27 1.416l-24.054 7.179a6.644 6.644 0 0 1-1.91.281l-.02-.029Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M29.073 87.26c-.312 0-.624-.117-.858-.36a1.208 1.208 0 0 1 0-1.716l2.758-2.746a1.669 1.669 0 0 0 0-2.377l-14.97-14.9a1.688 1.688 0 0 0-2.388 0l-2.806 2.794a1.222 1.222 0 0 1-1.726 0 1.208 1.208 0 0 1 0-1.717l2.807-2.794a4.149 4.149 0 0 1 5.838 0l14.97 14.9a4.103 4.103 0 0 1 0 5.811l-2.758 2.746a1.222 1.222 0 0 1-.857.359h-.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M24.346 96a3.16 3.16 0 0 1-2.232-.922L8.46 81.488a1.208 1.208 0 0 1 0-1.718 1.222 1.222 0 0 1 1.725 0l13.654 13.591a.726.726 0 0 0 1.024 0l4.035-4.016a.718.718 0 0 0 0-1.018L7.709 67.237a.726.726 0 0 0-1.023 0L2.65 71.253a.72.72 0 0 0-.214.504.72.72 0 0 0 .214.505l3.04 3.027a1.208 1.208 0 0 1 0 1.717 1.222 1.222 0 0 1-1.724 0L.926 73.979A3.11 3.11 0 0 1 0 71.757c0-.844.331-1.63.926-2.221L4.96 65.52a3.175 3.175 0 0 1 4.464 0l21.188 21.09a3.14 3.14 0 0 1 0 4.442l-4.035 4.017a3.16 3.16 0 0 1-2.232.921V96ZM22.221 60.699a1.216 1.216 0 0 1-1.218-1.213v-22.72a.697.697 0 0 0-.702-.698h-5.487a3.113 3.113 0 0 1-2.924-1.998 3.081 3.081 0 0 1 .819-3.435L45.612.815a3.152 3.152 0 0 1 4.23 0L69.315 18.44c.497.447.536 1.213.078 1.717a1.222 1.222 0 0 1-1.725.078L48.195 2.61a.705.705 0 0 0-.946 0L14.337 32.43c-.322.291-.234.63-.186.766a.673.673 0 0 0 .653.447h5.487a3.135 3.135 0 0 1 3.139 3.123v22.72c0 .67-.546 1.213-1.218 1.213h.01ZM72.765 61.872a1.216 1.216 0 0 1-1.218-1.212V42.548c0-.67.546-1.213 1.218-1.213.672 0 1.218.544 1.218 1.213V60.66c0 .669-.546 1.212-1.218 1.212Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M53.35 54.325a1.216 1.216 0 0 1-1.218-1.213v-8.798h-9.278v5.345c0 .67-.546 1.212-1.218 1.212a1.216 1.216 0 0 1-1.219-1.212V43.1c0-.67.546-1.213 1.219-1.213H53.35c.672 0 1.218.544 1.218 1.213v10.011c0 .67-.546 1.213-1.218 1.213Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M79.656 45.1c-7.164 0-14.824-4.434-14.824-16.89 0-11.204 13.42-23.835 13.986-24.369l.828-.776.828.776c.575.534 13.986 13.165 13.986 24.37 0 12.445-7.65 16.888-14.824 16.888h.02Zm0-38.668C76.75 9.352 67.268 19.567 67.268 28.21c0 8.644 4.63 14.464 12.388 14.464s12.387-5.403 12.387-14.464c0-9.06-9.483-18.858-12.387-21.778Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="m79.685 29.607-6.491-6.46a1.208 1.208 0 0 1 0-1.718 1.221 1.221 0 0 1 1.725 0l4.776 4.754 4.707-4.686a1.222 1.222 0 0 1 1.725 0 1.208 1.208 0 0 1 0 1.717l-6.432 6.403-.01-.01Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="m79.685 37.29-6.491-6.46a1.208 1.208 0 0 1 0-1.718 1.221 1.221 0 0 1 1.725 0l4.776 4.754 4.707-4.686a1.222 1.222 0 0 1 1.725 0 1.208 1.208 0 0 1 0 1.717L79.695 37.3l-.01-.01Z"
    />
  </svg>
)

export const CommunityLandTrustIcon = memo(CommunityLandTrustIconComponent)
CommunityLandTrustIcon.displayName = "CommunityLandTrustIcon"

export default CommunityLandTrustIcon
