import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const RealtyIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={PrimaryStrokeColor}
      d="M46.041 76.34c-5.585 8.328-10.448 14.16-11.812 15.75-.614.711-1.794.711-2.398 0-1.579-1.844-9.834-11.703-17.387-24.625C6.48 53.842 2.437 42.256 2.437 33.05c0-16.881 13.722-30.612 30.593-30.612 14.073 0 30.515 8.348 30.515 31.89 0 .185.049.36.117.516a1.272 1.272 0 0 0-.117.43c-.487 7.197-3.265 15.788-8.274 25.55a1.215 1.215 0 0 0 .526 1.638c.175.088.37.137.556.137a1.22 1.22 0 0 0 1.081-.663c5.156-10.055 8.031-18.968 8.538-26.497.01-.214-.039-.4-.127-.585.078-.156.127-.331.127-.517 0-10.864-3.528-19.845-10.204-25.98C49.91 2.966 41.84 0 33.03 0 14.814 0 0 14.823 0 33.05c0 25.287 28.751 59.195 29.98 60.629a4.015 4.015 0 0 0 6.101 0c1.383-1.619 6.325-7.538 11.977-15.974a1.22 1.22 0 0 0-.33-1.687 1.21 1.21 0 0 0-1.687.331v-.01Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M21.92 44.09h22.669c1.189 0 2.154-.966 2.154-2.156V29.54h2.378c.896 0 1.686-.536 2.008-1.375a2.141 2.141 0 0 0-.556-2.37L34.813 11.46a2.141 2.141 0 0 0-2.894 0l-15.984 14.55a2.141 2.141 0 0 0-.556 2.37 2.132 2.132 0 0 0 2.008 1.375h2.378v12.17c0 1.19.965 2.156 2.154 2.156v.01Zm-3.792-16.765L33.37 13.448 48.39 27.111h-1.93a2.155 2.155 0 0 0-2.154 2.155v12.395h-6.988v-8.874H29.2v8.874h-6.988v-12.17c0-1.19-.965-2.156-2.154-2.156h-1.93v-.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M86.234 68.353c3.509-2.438 5.809-6.495 5.809-11.079 0-7.44-6.052-13.497-13.489-13.497-7.436 0-13.489 6.056-13.489 13.497 0 4.584 2.3 8.64 5.81 11.079-5.361-.234-12.612-2.263-14.951-4.262-4.006-3.413-4.425-9.557-4.425-9.596-.146-3.618-3.255-6.456-6.823-6.31a6.589 6.589 0 0 0-4.59 2.097 6.519 6.519 0 0 0-1.754 4.73c.02.478.507 11.751 8.927 19.016.351.303.702.595 1.072.868 2.31 1.775 4.951 3.082 7.836 3.911 1.755.497 3.704.829 5.819.965V95.99H96V78.612c0-5.5-4.337-9.996-9.766-10.26ZM67.512 57.274c0-6.095 4.96-11.059 11.052-11.059 6.091 0 11.052 4.964 11.052 11.06 0 6.094-4.96 11.058-11.052 11.058-6.091 0-11.052-4.964-11.052-11.059Zm26.051 36.288h-29.14v-16.12l-1.18-.04c-2.359-.077-4.513-.39-6.403-.936a21.127 21.127 0 0 1-7.027-3.5 17.59 17.59 0 0 1-.955-.78c-7.622-6.583-8.07-16.833-8.08-17.262a4.093 4.093 0 0 1 1.111-2.974 4.191 4.191 0 0 1 2.895-1.327c2.251-.087 4.2 1.697 4.298 3.999.02.292.487 7.255 5.273 11.332 3.216 2.74 12.065 4.866 17.582 4.866h.175a.863.863 0 0 1 .166-.02l13.45-.029c4.327 0 7.845 3.52 7.845 7.85v14.941h-.01Z"
    />
  </svg>
)

export const RealtyIcon = memo(RealtyIconComponent)

export default RealtyIcon
