import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const TraditionalHomeownershipIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    {" "}
    <path
      fill={PrimaryStrokeColor}
      d="M84.435 83.642a1.219 1.219 0 0 1-1.218-1.218V8.83c0-1.589-.653-3.119-1.851-4.308-1.335-1.325-3.225-2.085-5.184-2.085H9.5A1.219 1.219 0 0 1 9.5 0h66.692c2.592 0 5.106 1.014 6.889 2.797 1.656 1.647 2.562 3.791 2.562 6.033v73.594c0 .672-.546 1.218-1.218 1.218h.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M86.5 96H26.063c-5.242 0-9.5-3.957-9.5-8.82V13.85H1.219A1.218 1.218 0 0 1 0 12.63V8.83C0 3.957 4.258 0 9.5 0S19 3.957 19 8.82v78.35c0 3.518 3.166 6.384 7.063 6.384s7.064-2.866 7.064-6.384v-3.801c0-.673.546-1.218 1.218-1.218h60.447c.672 0 1.218.545 1.218 1.218v3.801c0 4.863-4.258 8.82-9.5 8.82l-.01.01Zm-53.9-2.437h53.9c3.898 0 7.064-2.865 7.064-6.383v-2.583H35.553v2.583c0 2.514-1.13 4.775-2.952 6.383ZM2.437 11.413h14.128V8.83c0-3.518-3.167-6.384-7.064-6.384-3.898 0-7.064 2.866-7.064 6.384v2.583Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M58.79 35.486H43.582a1.847 1.847 0 0 1-1.841-1.842v-7.758H40.54c-.77 0-1.441-.458-1.714-1.18a1.842 1.842 0 0 1 .477-2.027l10.718-9.756a1.84 1.84 0 0 1 2.484 0l10.572 9.61c.565.517.76 1.316.477 2.027a1.836 1.836 0 0 1-1.715 1.18h-1.198v7.914a1.847 1.847 0 0 1-1.842 1.841l-.01-.01Zm-14.614-2.437h14.02v-7.914c0-1.013.828-1.842 1.842-1.842h.253l-9.042-8.216-9.188 8.362h.254c1.013 0 1.841.829 1.841 1.843v7.757l.02.01Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M53.09 28.322h-3.809v5.945h3.81v-5.945Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M53.578 34.755h-4.784v-6.92h4.784v6.92Zm-3.81-.975h2.836v-4.97h-2.836v4.97ZM73.133 58.223H29.239a1.218 1.218 0 0 1 0-2.436h43.884a1.218 1.218 0 0 1 0 2.436h.01ZM73.133 48.983H29.239a1.218 1.218 0 0 1 0-2.435h43.884a1.218 1.218 0 0 1 0 2.435h.01ZM73.133 67.024H29.239a1.218 1.218 0 0 1 0-2.436h43.884a1.218 1.218 0 0 1 0 2.436h.01Z"
    />
  </svg>
)

export const TraditionalHomeownershipIcon = memo(
  TraditionalHomeownershipIconComponent,
)
TraditionalHomeownershipIcon.displayName = "TraditionalHomeownershipIcon"

export default TraditionalHomeownershipIcon
