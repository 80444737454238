import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const FinancialCounselingIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={PrimaryStrokeColor}
      d="M44.452 49.487c-.497 0-.994-.155-1.423-.445l-13.362-9.293H2.476A2.48 2.48 0 0 1 0 37.278V2.46A2.472 2.472 0 0 1 2.476 0h52.629a2.472 2.472 0 0 1 2.476 2.461v34.807a2.472 2.472 0 0 1-2.476 2.462h-8.128l-.04 7.287c0 .92-.506 1.763-1.335 2.19a2.51 2.51 0 0 1-1.14.28h-.01ZM2.476 2.423l-.04 34.855 27.212.039c.517 0 1.004.155 1.423.445l13.362 9.293.068-7.277a2.472 2.472 0 0 1 2.476-2.461h8.128s.039-.02.039-.04V2.462L2.476 2.423ZM79.548 30.815c-6.003 0-10.896-4.865-10.896-10.834 0-5.97 4.883-10.834 10.896-10.834 6.014 0 10.897 4.855 10.897 10.834 0 5.979-4.893 10.834-10.897 10.834Zm0-19.245c-4.668 0-8.46 3.77-8.46 8.411 0 4.642 3.792 8.411 8.46 8.411 4.669 0 8.46-3.77 8.46-8.411 0-4.642-3.791-8.411-8.46-8.411Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M64.227 50.592a1.215 1.215 0 0 1-1.218-1.211V36.764c0-4.583 3.752-8.314 8.362-8.314.673 0 1.219.543 1.219 1.212 0 .668-.546 1.21-1.219 1.21-3.274 0-5.925 2.646-5.925 5.892v12.617c0 .669-.546 1.211-1.219 1.211Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M96 50.592H51.577a1.215 1.215 0 0 1-1.218-1.211c0-.669.545-1.211 1.218-1.211h41.987V36.716c0-3.256-2.661-5.892-5.936-5.892H71.459a1.215 1.215 0 0 1-1.218-1.21c0-.67.546-1.212 1.218-1.212h16.169c4.61 0 8.372 3.73 8.372 8.314v13.876ZM60.426 95.816a2.25 2.25 0 0 1-1.023-.252 2.194 2.194 0 0 1-1.199-1.957v-5.543h-6.247a2.215 2.215 0 0 1-2.222-2.21V56.65c0-1.221.994-2.21 2.222-2.21h41.83c1.229 0 2.223.989 2.223 2.21v29.206c0 1.22-.994 2.209-2.222 2.209h-21.53l-10.555 7.345c-.38.262-.828.397-1.277.397v.01Zm-8.255-10.175h6.248c1.228 0 2.222.989 2.222 2.21v5.339l10.272-7.151c.37-.262.819-.398 1.277-.398h21.373v-28.78H52.171v28.78ZM21.12 74.159c-7.641 0-13.86-6.183-13.86-13.78 0-7.597 6.219-13.78 13.86-13.78 7.641 0 13.86 6.183 13.86 13.78 0 7.597-6.219 13.78-13.86 13.78Zm0-25.137c-6.296 0-11.423 5.097-11.423 11.357 0 6.26 5.127 11.357 11.423 11.357s11.422-5.097 11.422-11.357c0-6.26-5.126-11.357-11.422-11.357Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M51.577 96H0V82.23c0-5.785 4.737-10.494 10.555-10.494h21.12c5.819 0 10.555 4.71 10.555 10.495v11.347h9.347c.672 0 1.218.542 1.218 1.21 0 .67-.546 1.212-1.218 1.212Zm-49.14-2.422h37.357V82.23c0-4.448-3.645-8.072-8.119-8.072h-21.12c-4.473 0-8.118 3.624-8.118 8.072v11.347ZM65.163 74.159a2.459 2.459 0 0 0 2.466-2.452 2.459 2.459 0 0 0-2.466-2.452 2.459 2.459 0 0 0-2.466 2.452 2.459 2.459 0 0 0 2.466 2.452Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M65.163 74.653a2.949 2.949 0 0 1-2.953-2.936c0-1.619 1.325-2.936 2.953-2.936s2.953 1.317 2.953 2.936c0 1.618-1.325 2.936-2.953 2.936Zm0-4.904a1.974 1.974 0 0 0-1.978 1.968c0 1.085.886 1.967 1.978 1.967a1.974 1.974 0 0 0 1.978-1.967 1.974 1.974 0 0 0-1.978-1.968ZM73.798 74.159a2.459 2.459 0 0 0 2.466-2.452 2.459 2.459 0 0 0-2.466-2.452 2.459 2.459 0 0 0-2.466 2.452 2.459 2.459 0 0 0 2.466 2.452Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M73.798 74.653c-1.627 0-2.953-1.318-2.953-2.936 0-1.619 1.326-2.936 2.953-2.936 1.628 0 2.953 1.317 2.953 2.936 0 1.618-1.325 2.936-2.953 2.936Zm0-4.904a1.974 1.974 0 0 0-1.978 1.968c0 1.085.887 1.967 1.978 1.967a1.974 1.974 0 0 0 1.979-1.967 1.974 1.974 0 0 0-1.979-1.968ZM82.433 74.159a2.459 2.459 0 0 0 2.466-2.452 2.459 2.459 0 0 0-2.466-2.452 2.459 2.459 0 0 0-2.466 2.452 2.459 2.459 0 0 0 2.466 2.452Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M82.433 74.653c-1.627 0-2.953-1.318-2.953-2.936 0-1.619 1.326-2.936 2.953-2.936 1.628 0 2.953 1.317 2.953 2.936 0 1.618-1.325 2.936-2.953 2.936Zm0-4.904a1.974 1.974 0 0 0-1.978 1.968c0 1.085.887 1.967 1.978 1.967a1.974 1.974 0 0 0 1.979-1.967 1.974 1.974 0 0 0-1.979-1.968Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M29.502 29.187c-5.984 0-10.848-4.836-10.848-10.785 0-5.95 4.864-10.786 10.848-10.786 5.984 0 10.847 4.836 10.847 10.786 0 5.95-4.863 10.785-10.847 10.785Zm0-19.138c-4.64 0-8.411 3.75-8.411 8.362 0 4.613 3.772 8.363 8.41 8.363 4.64 0 8.412-3.75 8.412-8.363 0-4.612-3.772-8.362-8.411-8.362Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M39.121 19.613h-9.629a1.213 1.213 0 0 1-1.12-1.686L31.5 10.63c.263-.61.984-.901 1.598-.64.624.262.907.97.643 1.59l-2.407 5.61h7.777c.673 0 1.219.543 1.219 1.212 0 .668-.546 1.21-1.218 1.21h.01Z"
    />
  </svg>
)

export const FinancialCounselingIcon = memo(FinancialCounselingIconComponent)
FinancialCounselingIcon.displayName = "FinancialCounselingIcon"

export default FinancialCounselingIcon
