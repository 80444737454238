import { MemoExoticComponent } from "react"

import {
  RealServiceKind,
  ServiceKind,
  VirtualServiceKind,
} from "@/packages/homebuyerPath/index.types"

import CommunityLandTrustIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/CommunityLandTrustIcon"
import CreditCounselingIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/CreditCounselingIcon"
import DownPaymentAssistanceIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/DownPaymentAssistanceIcon"
import ExploreOptionsAltIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/ExploreOptionsAltIcon"
import ExploreOptionsIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/ExploreOptionsIcon"
import FinancialCounselingIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/FinancialCounselingIcon"
import HomebuyerEducationIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/HomebuyerEducationIcon"
import HomeownerAltIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/HomeownerAltIcon"
import HomeownerIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/HomeownerIcon"
import Label from "@/Components/HomebuyerPath/HomebuyerPathIcons/Label"
import LendingIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/LendingIcon"
import LimitedEquityCoOpIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/LimitedEquityCoOpIcon"
import PrePurchaseCounselingIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/PrePurchaseCounselingIcon"
import RealtyIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/RealtyIcon"
import SelfHelpHousingIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/SelfHelpHousingIcon"
import TraditionalHomeownershipIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/TraditionalHomeownershipIcon"
import traditionalHomeownershipIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/TraditionalHomeownershipIcon"
import WhrcAltIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/WhrcAltIcon"
import WhrcIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/WhrcIcon"
import WhrcPortalPathIcon from "@/Components/HomebuyerPath/HomebuyerPathIcons/WhrcPortalPathIcon"

export type VirtualServiceKindsAsComponentNames =
  | "ExploreOptions"
  | "TraditionalHomeownership"
  | "WhrcPortalPath"

export type RealServiceKindsAsComponentNames =
  | "CommunityLandTrust"
  | "CreditCounseling"
  | "DownPaymentAssistance"
  | "FinancialCounseling"
  | "HomebuyerEducation"
  | "Homeowner"
  | "Lending"
  | "LimitedEquityCoOp"
  | "PrePurchaseCounseling"
  | "Realty"
  | "SelfHelpHousing"
  | "Whrc"
export type RealServiceKindAltsAsComponentNames =
  | "ExploreOptionsAlt"
  | "HomeownerAlt"
  | "WhrcAlt"
export type ServiceKindAltsAsComponentNames =
  | VirtualServiceKindsAsComponentNames
  | RealServiceKindsAsComponentNames
  | RealServiceKindAltsAsComponentNames
const serviceKindToComponentNameMap: Record<
  RealServiceKind | VirtualServiceKind,
  | RealServiceKindsAsComponentNames
  | VirtualServiceKindsAsComponentNames
  | RealServiceKindAltsAsComponentNames
> = {
  "whrc portal path": "WhrcPortalPath",
  "community land trust": "CommunityLandTrust",
  "pre-purchase counseling": "PrePurchaseCounseling",
  "down payment assistance": "DownPaymentAssistance",
  "homebuyer education": "HomebuyerEducation",
  "financial counseling": "FinancialCounseling",
  "limited equity co-op": "LimitedEquityCoOp",
  "self-help housing": "SelfHelpHousing",
  "credit counseling": "CreditCounseling",
  "explore options": "ExploreOptions",
  "traditional homeownership": "TraditionalHomeownership",
  realty: "Realty",
  lending: "Lending",
  homeowner: "Homeowner",
  whrc: "Whrc",
}
export function homebuyerPathIconFromServiceKind(
  serviceKind: RealServiceKind | VirtualServiceKind,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): MemoExoticComponent<any> {
  return icons[serviceKindToComponentNameMap[serviceKind]]
}

const byServiceKind: Record<
  ServiceKind,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MemoExoticComponent<any>
> = {
  "community land trust": CommunityLandTrustIcon,
  "pre-purchase counseling": PrePurchaseCounselingIcon,
  "down payment assistance": DownPaymentAssistanceIcon,
  "homebuyer education": HomebuyerEducationIcon,
  "financial counseling": FinancialCounselingIcon,
  "limited equity co-op": LimitedEquityCoOpIcon,
  "self-help housing": SelfHelpHousingIcon,
  "credit counseling": CreditCounselingIcon,
  "explore options": ExploreOptionsIcon,
  "traditional homeownership": traditionalHomeownershipIcon,
  homeowner: HomeownerIcon,
  realty: RealtyIcon,
  lending: LendingIcon,
  whrc: WhrcIcon,
  "whrc portal path": WhrcPortalPathIcon,
}

const icons: Record<
  | RealServiceKindsAsComponentNames
  | VirtualServiceKindsAsComponentNames
  | RealServiceKindAltsAsComponentNames,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MemoExoticComponent<any>
> = {
  CommunityLandTrust: CommunityLandTrustIcon,
  CreditCounseling: CreditCounselingIcon,
  DownPaymentAssistance: DownPaymentAssistanceIcon,
  ExploreOptions: ExploreOptionsIcon,
  ExploreOptionsAlt: ExploreOptionsAltIcon,
  FinancialCounseling: FinancialCounselingIcon,
  HomebuyerEducation: HomebuyerEducationIcon,
  HomeownerAlt: HomeownerAltIcon,
  Homeowner: HomeownerIcon,
  Lending: LendingIcon,
  LimitedEquityCoOp: LimitedEquityCoOpIcon,
  PrePurchaseCounseling: PrePurchaseCounselingIcon,
  Realty: RealtyIcon,
  SelfHelpHousing: SelfHelpHousingIcon,
  Whrc: WhrcIcon,
  WhrcAlt: WhrcAltIcon,
  TraditionalHomeownership: TraditionalHomeownershipIcon,
  WhrcPortalPath: WhrcPortalPathIcon,
}
export default Object.assign(icons, {
  byServiceKind,
  Label,
})
