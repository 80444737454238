function capitalize(str: string) {
  if (str.length == 0) return str
  return str[0].toUpperCase() + str.substring(1)
}

export default function titleCase(str?: string) {
  if (!str) {
    return "UNKNOWN"
  }

  if (str === "whrc") {
    return "WHRC"
  }

  return str
    .split(" ")
    .map(capitalize)
    .join(" ")
    .split("-")
    .map(capitalize)
    .join("-")
}
