import { SVGProps, memo } from "react"

import {
  AccentStrokeColor,
  HomebuyerPathIcon,
  PrimaryStrokeColor,
} from "@/Components/HomebuyerPath/HomebuyerPathIcons/Support"

const LendingIconComponent = ({
  withAccent = false,
  ...props
}: SVGProps<SVGSVGElement> & HomebuyerPathIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <path
      fill={PrimaryStrokeColor}
      d="M84.435 83.642a1.219 1.219 0 0 1-1.218-1.218V8.83c0-1.589-.653-3.119-1.851-4.308-1.335-1.325-3.225-2.085-5.184-2.085H9.5A1.219 1.219 0 0 1 9.5 0h66.692c2.592 0 5.106 1.014 6.889 2.797 1.656 1.647 2.562 3.791 2.562 6.033v73.594c0 .672-.546 1.218-1.218 1.218h.01Z"
    />
    <path
      fill={PrimaryStrokeColor}
      d="M86.5 96H26.063c-5.242 0-9.5-3.957-9.5-8.82V13.85H1.219A1.218 1.218 0 0 1 0 12.63V8.83C0 3.957 4.258 0 9.5 0S19 3.957 19 8.82v78.35c0 3.518 3.166 6.384 7.063 6.384s7.064-2.866 7.064-6.384v-3.801c0-.673.546-1.218 1.218-1.218h60.447c.672 0 1.218.545 1.218 1.218v3.801c0 4.863-4.258 8.82-9.5 8.82l-.01.01Zm-53.9-2.437h53.9c3.898 0 7.064-2.865 7.064-6.383v-2.583H35.553v2.583c0 2.514-1.13 4.775-2.952 6.383ZM2.437 11.413h14.128V8.83c0-3.518-3.167-6.384-7.064-6.384-3.898 0-7.064 2.866-7.064 6.384v2.583Z"
    />
    <path
      fill={withAccent ? AccentStrokeColor : PrimaryStrokeColor}
      d="M73.133 58.224H29.239a1.219 1.219 0 0 1 0-2.437h43.884a1.218 1.218 0 0 1 0 2.437h.01Zm1.218-10.448c0-.673-.546-1.218-1.218-1.218H29.239a1.219 1.219 0 0 0 0 2.436h43.884c.672 0 1.218-.546 1.218-1.218h.01Zm0 18.03c0-.672-.546-1.218-1.218-1.218H29.239a1.219 1.219 0 0 0 0 2.437h43.884c.672 0 1.218-.546 1.218-1.219h.01Zm-8.623-42.298c0 8.021-6.518 14.541-14.537 14.541-8.019 0-14.537-6.52-14.537-14.541S43.172 8.967 51.191 8.967c8.019 0 14.537 6.52 14.537 14.54ZM51.19 35.613c6.674 0 12.101-5.429 12.101-12.105s-5.427-12.105-12.101-12.105c-6.674 0-12.101 5.429-12.101 12.105s5.427 12.105 12.1 12.105Zm3.615-11.53a2.7 2.7 0 0 0-.848-.877 5.295 5.295 0 0 0-1.081-.527c-.38-.136-.76-.253-1.14-.37a8.629 8.629 0 0 1-1.004-.36 2.136 2.136 0 0 1-.662-.449c-.137-.146-.205-.341-.205-.594 0-.273.068-.478.214-.634.166-.175.37-.302.653-.39.526-.165 1.15-.175 1.744-.068.205.04.4.088.575.146l.224 1.608h1.432l.156-2.816-.185-.078a6.403 6.403 0 0 0-.926-.322 10.188 10.188 0 0 0-1.023-.224 2.99 2.99 0 0 0-.292-.039l.088-.058h-.215v-1.872h-1.607v1.95c-.37.058-.712.155-1.033.292a3.16 3.16 0 0 0-1.325 1.014c-.331.448-.507 1.013-.507 1.686 0 .526.117.974.331 1.325.215.35.507.643.848.858.331.214.702.38 1.091.516.38.127.75.244 1.13.36.361.108.692.225.995.352.272.117.487.273.652.448.146.166.215.37.215.643 0 .293-.078.527-.234.712-.176.204-.41.35-.692.458-.546.195-1.257.214-1.93.068a4.833 4.833 0 0 1-.73-.214l-.224-1.667H47.83l-.05 2.992.186.078c.516.205 1.052.37 1.588.468.37.068.721.117 1.052.146l-.117.088.234-.02v1.94h1.608v-2.115c.36-.078.692-.185 1.013-.331.546-.254.994-.605 1.316-1.053.33-.458.506-1.033.506-1.696 0-.546-.117-1.004-.331-1.364l-.03-.01Z"
    />
  </svg>
)

export const LendingIcon = memo(LendingIconComponent)

export default LendingIcon
