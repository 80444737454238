import classNames from "@/util/classNames"
import titleCase from "@/util/titleCase"

import { ServiceKind } from "@/packages/homebuyerPath/index.types"

type Size = "xs" | "sm" | "base" | "xl"

const classes = "text-center"

// w-12 md:w-16 xl:w-20 2xl:w-24 3xl:w-32 h-8 mt-2
const sizeClasses: Record<Size, string> = {
  xs: "w-12", // 48
  sm: "w-12 md:w-16", // 64
  base: "text-xxxs w-12 md:w-16 xl:text-xxs xl:w-20 2xl:text-xs 2xl:w-24", // 96
  xl: "w-12 md:w-16 xl:w-20 2xl:w-24 2xl:w-24 3xl:w-32", // 128
}

export const LabelComponent = ({
  serviceKind,
  size = "base",
}: {
  serviceKind: ServiceKind
  size?: Size
}) => {
  return (
    <div className={classNames(classes, sizeClasses[size], "overflow-hidden")}>
      {titleCase(serviceKind)}
    </div>
  )
}
export const Label = LabelComponent
export default Label
